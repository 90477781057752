import type { SVGProps } from 'react';

export function Share(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.33334 2H14V6.66667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
      <path d="M14 9.82457V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2H6"
            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.59995 7.39996L13.6999 2.29996" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>

  );
}
