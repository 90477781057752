import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseModal from '@/components/base/baseModal';
import BaseTabs from '@/components/base/baseTabs';
import ValueDisplay from '@/components/display/displayValueDisplay';
import { updateTpSlApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import { truncateNumber } from '@/utils/number';
import RenderUtil from '@/utils/RenderUtil';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

/**
 *  修改止盈止损
 */
export default function UpdateTpTl(props: any) {
  const { isOpen, onOpenChange, curItem, onClose } = props || {};
  const { t } = useTranslation();
  const { getSocketRowByName } = useModel('socket');
  const soketData = getSocketRowByName(curItem?.symbol);

  // 价格精度
  const priceAccuracyLength = curItem?.priceAccuracy ?? 4;

  //止盈
  const [tp, setTp] = useState<any>(0);
  //止损
  const [tl, setTl] = useState<any>(0);
  const { reLoadOrder, currentUpClass, currentDownClass } = useModel('system');
  useEffect(() => {
    if (isOpen) {
      setTp(curItem?.stopProfitPrice);
      setTl(curItem?.stopLossPrice);
    }
  }, [isOpen]);

  const { run: submitRequest, loading: submitLoading } = useReq(updateTpSlApi, {
    manual: true,
    loadingDefault: false,
    onSuccess: () => {
      onClose();
      toast.success(t('修改成功'));
      reLoadOrder();
    },
  });

  // 渲染国际化引擎(主要是为了能正确解析jsx)
  const renderI18Template = (
    template: string,
    params: any,
  ): { __html: string } => {
    const renderedTemplate = template.replace(/{{(\w+)}}/g, (match, p1) => {
      const paramValue = params[p1];
      if (typeof paramValue === 'string' || typeof paramValue === 'number') {
        return String(paramValue);
      } else if (React.isValidElement(paramValue)) {
        return ReactDOMServer.renderToStaticMarkup(paramValue);
      } else {
        return '';
      }
    });

    return { __html: renderedTemplate };
  };
  // 交易精度，后台控制值，输入框
  const transactionAccuracy = curItem?.transactionAccuracy ?? 6;

  // 币种数量计算
  const coinNum = useMemo(() => {
    return (curItem?.num * curItem?.symbolEarnestMoney) / curItem.endPrice;
  }, [curItem]);

  // 预估花费（止盈）
  const estimatedCost = useMemo(() => {
    let _profit = 0;
    if (curItem?.direction === 1) {
      _profit = (tp - curItem.endPrice) * curItem?.firstHand * coinNum;
    } else {
      _profit = (curItem.endPrice - tp) * curItem?.firstHand * coinNum;
    }
    return {
      profit: _profit,
      plRatio: (_profit / curItem?.earnestMoney) * 100,
    };
  }, [curItem, soketData, tp, coinNum]);
  // 预估花费（止损）
  const estimatedCostLoss = useMemo(() => {
    let _profit = 0;
    if (curItem?.direction === 1) {
      _profit = (tl - curItem.endPrice) * curItem?.firstHand * coinNum;
    } else {
      _profit = (curItem.endPrice - tl) * curItem?.firstHand * coinNum;
    }
    return {
      profit: _profit,
      plRatio: (_profit / curItem?.earnestMoney) * 100,
    };
  }, [curItem, soketData, tl, coinNum]);

  // 价格精度

  return (
    <BaseModal
      isOpen={isOpen}
      hideCloseButton
      onClose={onClose}
      isShowCloseButton
    >
      <div className="pb-10 text-foreground px-4  flex flex-col justify-between max-h-[80vh] overflow-y-auto sm:pb-8">
        <div className="pb-4 text-xs">
          <div className="pb-4">
            <div className="flex justify-between items-center">
              <BaseTabs
                options={[{ text: t('仓位止盈/止损'), value: '1' }]}
                value={'1'}
                tabsPropsProps={{
                  classNames: {
                    cursor: 'hidden',
                    tabContent: '!text-[16px]',
                  },
                }}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div className=" text-auxiliaryTextColor">{t('开仓价格')}</div>
                <div className=" font-bold">
                  {RenderUtil.FormatAmount(
                    curItem?.endPrice,
                    priceAccuracyLength,
                  )}
                  {ProjectSetting.APP_DEFAULT_CURRENCY}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-auxiliaryTextColor">
                  {t('数量')}({t('张')})
                </div>
                <div className="font-bold">
                  {truncateNumber(curItem?.num, transactionAccuracy)}
                  {' ' + t('张')}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className=" text-auxiliaryTextColor">{t('最新价格')}</div>
                <div className="font-bold">
                  <ValueDisplay
                    value={soketData?.a}
                    len={priceAccuracyLength}
                    suffix={ProjectSetting.APP_DEFAULT_CURRENCY}
                    isSubLen={false}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-auxiliaryTextColor">{t('强平价格')}</div>
                <div className="font-bold">
                  {!curItem?.forcedLiquidationPrice ||
                  curItem?.forcedLiquidationPrice === '0'
                    ? '--'
                    : RenderUtil.FormatAmount(
                        curItem?.forcedLiquidationPrice,
                        priceAccuracyLength,
                        false,
                      )}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <div className="font-bold">{t('止盈')}</div>
              <BaseInputNumber
                value={tp}
                max={9999999999999}
                step={1 / Math.pow(10, priceAccuracyLength)}
                min={0.00001}
                defaultValue=""
                // len={99}
                len={tp === 0 ? 0 : priceAccuracyLength}
                onChange={(value: string, isAdd: any) => {
                  if (!tp && soketData?.a && isAdd) {
                    setTp(soketData?.a);
                  } else if (Number(value) === 0 && isAdd) {
                    setTp(1 / Math.pow(10, priceAccuracyLength));
                  } else {
                    setTp(value);
                  }
                }}
                className="py-6"
              />
              {!!tp && (
                <div
                  className="text-auxiliaryTextColor  bg-backgroundAuxiliaryColor rounded-md p-2 "
                  dangerouslySetInnerHTML={renderI18Template(
                    estimatedCost?.profit >= 0
                      ? t(
                          '最新成交价达到 {{newPrice}} 将触发市价止盈单，预期盈利将为 {{profit}}USDT(回报率：{{plRatio}})',
                        )
                      : t(
                          '最新成交价达到 {{newPrice}} 将触发市价止盈单，预期亏损将为 {{profit}}USDT(回报率：{{plRatio}})',
                        ),
                    {
                      newPrice: RenderUtil.FormatAmount(
                        tp,
                        priceAccuracyLength,
                      ),
                      profit: (
                        <span
                          className={
                            estimatedCost?.profit >= 0
                              ? currentUpClass
                              : currentDownClass
                          }
                        >
                          {estimatedCost?.profit > 0 ? '+' : '-'}
                          {RenderUtil.FormatAmount(
                            Math.abs(estimatedCost?.profit),
                            priceAccuracyLength,
                          )}
                        </span>
                      ),
                      plRatio: (
                        <span
                          className={
                            estimatedCost?.profit >= 0
                              ? currentUpClass
                              : currentDownClass
                          }
                        >
                          {estimatedCost?.profit > 0 ? '+' : '-'}
                          {RenderUtil.FormatAmount(
                            Math.abs(estimatedCost?.plRatio),
                            2,
                          )}{' '}
                          %
                        </span>
                      ),
                    },
                  )}
                ></div>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <div className="font-bold">{t('止损')}</div>
              <BaseInputNumber
                value={tl}
                // len={99}
                defaultValue=""
                len={tl === 0 ? 0 : priceAccuracyLength}
                max={9999999999999}
                step={1 / Math.pow(10, priceAccuracyLength)}
                min={0.0001}
                className="py-6"
                onChange={(value: string, isAdd: any) => {
                  if (!tl && soketData?.a && isAdd) {
                    setTl(soketData?.a);
                  } else if (Number(value) === 0 && isAdd) {
                    setTl(1 / Math.pow(10, priceAccuracyLength));
                  } else {
                    setTl(value);
                  }
                }}
              />
              {!!tl && (
                <div
                  className="text-auxiliaryTextColor  bg-backgroundAuxiliaryColor rounded-md p-2 "
                  dangerouslySetInnerHTML={renderI18Template(
                    estimatedCostLoss?.profit >= 0
                      ? t(
                          '最新成交价达到 {{newPrice}} 将触发市价止损单，预期盈利将为 {{profit}}USDT(回报率：{{plRatio}})',
                        )
                      : t(
                          '最新成交价达到 {{newPrice}} 将触发市价止损单，预期亏损将为 {{profit}}USDT(回报率：{{plRatio}})',
                        ),
                    {
                      newPrice: RenderUtil.FormatAmount(
                        tl,
                        priceAccuracyLength,
                      ),
                      profit: (
                        <span
                          className={
                            estimatedCostLoss?.profit >= 0
                              ? currentUpClass
                              : currentDownClass
                          }
                        >
                          {estimatedCostLoss?.profit > 0 ? '+' : '-'}
                          {RenderUtil.FormatAmount(
                            Math.abs(estimatedCostLoss?.profit),
                            priceAccuracyLength,
                          )}
                        </span>
                      ),
                      plRatio: (
                        <span
                          className={
                            estimatedCostLoss?.profit >= 0
                              ? currentUpClass
                              : currentDownClass
                          }
                        >
                          {estimatedCostLoss?.profit > 0 ? '+' : '-'}
                          {RenderUtil.FormatAmount(
                            Math.abs(estimatedCostLoss?.plRatio),
                            2,
                          )}{' '}
                          %
                        </span>
                      ),
                    },
                  )}
                ></div>
              )}
            </div>
          </div>
        </div>
        <Button
          spinner={<LoadingSvg />}
          isLoading={submitLoading}
          onClick={() => {
            if (curItem?.direction === 1) {
              // 做多
              if (tp !== '' && tp !== '0' && tp !== 0 && tp < soketData?.a) {
                toast.error(t('止盈价格不能低于当前价格'));
                return;
              }
              if (tl !== '' && tl !== '0' && tl !== 0 && tl > soketData?.a) {
                toast.error(t('止损价格不能高于当前价格'));
                return;
              }
            } else if (curItem?.direction === 2) {
              // 做空
              if (tp !== '' && tp !== '0' && tp !== 0 && tp > soketData?.a) {
                toast.error(t('止盈价格不能高于当前价格'));
                return;
              }
              if (tl !== '' && tl !== '0' && tl !== 0 && tl < soketData?.a) {
                toast.error(t('止损价格不能低于当前价格'));
                return;
              }
            }

            submitRequest({
              id: curItem?.id,
              stopProfitPrice: tp,
              stopLossPrice: tl,
            });
          }}
          className="mainColorButton w-full !py-[12px] rounded-md "
        >
          <div className="text-xs">
            <div>{t('确认')}</div>
          </div>
        </Button>
      </div>
    </BaseModal>
  );
}
