import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import BasePullList from '@/components/base/basePullList/index';
import BaseSift from '@/components/base/baseSift';
import BaseTabs from '@/components/base/baseTabs';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { toast } from '@/layouts/components/ToastMessage';
import ContractOrderCard from '@/pages/order/index/components/ContractOrderCard';
import useConstants from '@/pages/order/index/useConstants';
import {
  closeAPositionWithOneClickApi,
  getContractOrderListApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import {
  Button,
  Checkbox,
  Divider,
  cn,
  useDisclosure,
} from '@nextui-org/react';
import React, {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState, useRef, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { OrderEmptyStatus } from '@/assets/icons/comm/OrderEmptyStatus';
import RenderUtil from '@/utils/RenderUtil';
import ProjectSetting from '@/setting/projectSetting';
import { history } from '@umijs/max';
import { useInViewport, useThrottle } from 'ahooks';

// 合约订单状态
export enum ContractOrderStatusEnum {
  //历史订单
  HISTORY = '0',
  //委托中
  ON_COMMISSION = '1',
  //持仓中
  IN_POSITION = '2',
}

// 合约订单可接受参数
export interface ContractProps {
  symbol?: string;
  siftNode?: ReactNode;
  isShowSift?: boolean;
  isShowLoadingDisplay?: boolean;
  contractAvailableFunds?: number | string;
}

export default forwardRef(
  (
    {
      symbol,
      siftNode,
      isShowSift = false,
      isShowLoadingDisplay = true,
    }: ContractProps,
    ref,
  ) => {
    const { orderKey: sysOrderKey } = useModel('system');
    const {
      contractAvailableFunds,
    }: any = useModel('user');
    const { isMobile } = useModel('system');

    const { localContract } = useModel('contract');
    const { directionFilterOption, tradeTypeOption, marginModeOption } =
      useConstants();
    // 基础定义
    const { t } = useTranslation();
    const [ContractOrderStatusEnumMap] = useState([
      {
        text: t('持仓中'),
        value: ContractOrderStatusEnum.IN_POSITION,
      },
      {
        text: t('委托中'),
        value: ContractOrderStatusEnum.ON_COMMISSION,
      },
      {
        text: t('历史订单'),
        value: ContractOrderStatusEnum.HISTORY,
      },
    ]);
    const [direction, setDirection] = useState(directionFilterOption[0]);
    const [type, setType] = useState(tradeTypeOption[0]);
    const [earnestMode, setEarnestMode] = useState(tradeTypeOption[0]);
    const filterList = useMemo(() => {
      return [
        {
          options: directionFilterOption,
          onChange: setDirection,
          current: direction,
          title: t('方向'),
        },
        {
          options: tradeTypeOption,
          onChange: setType,
          current: type,
          title: t('类型'),
        },

        {
          options: marginModeOption,
          onChange: setEarnestMode,
          current: earnestMode,
          title: t('保证金模式'),
        },
      ];
    }, [
      setDirection,
      setType,
      setEarnestMode,
      tradeTypeOption,
      directionFilterOption,
      marginModeOption,
    ]);
    const viewRef = useRef<HTMLDivElement>(null);
    const [inViewport] = useInViewport(viewRef);
    const [currentTab, setCurrentTab] = useState<ContractOrderStatusEnum>(
      ContractOrderStatusEnum.IN_POSITION,
    );
    const [time, setTime] = useState<string[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pageParams, setPageParams] = useState<any>({
      pageNo: 1,
      pageSize: 10,
    });
    // 多币种持仓
    const [multiCurrencyPositions, setMultiCurrencyPositions] = useLocalStorage(
      {
        key: CacheEnum.APP_CONTRACT_POSITION_LIST,
        defaultValue: false,
      },
    );
    // 请求参数(依赖更新,分页hooks会自动发起请求)
    const params = useMemo(() => {
      return {
        status: currentTab,
        direction: direction?.value,
        type: type?.value,
        earnestMode: earnestMode?.value,
        startTime:
          time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
        endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
        symbolContractId: multiCurrencyPositions ? undefined : symbol,
        ...pageParams,
      };
    }, [
      currentTab,
      time,
      earnestMode,
      type,
      direction,
      pageParams,
      sysOrderKey,
      multiCurrencyPositions,
      symbol,
      inViewport,
    ]);

    // 一键平仓
    const {
      run: closeAPositionWithOneClickReq,
      loading: closeAPositionWithOneClickLoaing,
    } = useReq(closeAPositionWithOneClickApi, {
      manual: true,
      loadingDefault: false,
      onSuccess() {
        toast.success(t('平仓成功'));
      },
    });
    // 一键平仓提示是否关闭-缓存
    const [isCloseOneClick, setIsCloseOneClick] = useLocalStorage({
      key: CacheEnum.APP_CLOSE_REVERSE_TIPS,
      defaultValue: false,
    });
    //只平当前提示是否关闭
    const [isCloseCurrent, setIsCloseCurrent] = useLocalStorage({
      key: CacheEnum.APP_ONLY_CLOSE_TIPS,
      defaultValue: false,
    });
    const { openPrompt } = useGlobalPrompt();
    // 用于做外部加载
    const [loadingDisplay, setLoadingDisplay] = useState(false);
    // 暴露方法
    useImperativeHandle(ref, () => ({
      loadingDisplay,
    }));

    return (
      <div className="w-full " ref={viewRef}>
        <div className="flex justify-between items-center pr-4">
          <BaseTabs
            tabsPropsProps={{
              className: 'w-full',
              classNames: {
                tab: 'first:!pl-3',
                tabList: 'pb-0',
              },
            }}
            value={currentTab!}
            onChange={setCurrentTab}
            options={ContractOrderStatusEnumMap}
          />
          <div className="w-fit flex items-center h-fit ">
            {/* 加载 */}
            {isShowLoadingDisplay && loadingDisplay && (
              <div
                className={cn(
                  'flex items-center justify-center w-fit h-full ',
                  { 'mr-1': siftNode },
                )}
              >
                <SvgSpinnersBarsRotateFade />
              </div>
            )}
            {siftNode ? (
              siftNode
            ) : (
              <AntDesignFilterFilled
                className="text-iconFontColor text-base cursor-pointer"
                onClick={onOpen}
              />
            )}
          </div>
        </div>

        {/*时间筛选组件*/}
        <FeaturesDateTime
          open={isOpen}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={(value: any) => {
            const { start, end } = value;
            setTime?.([start, end]);
            onClose();
          }}
        />
        <Divider className="mb-2 !bg-backgroundAuxiliaryColor" />
        {/*是否多仓位持仓*/}
        <div
          className={cn(`flex items-center  pl-4 pt-2 pb-3`, {
            'justify-end': location.pathname === PageEnum.ORDER,
            'justify-between': location.pathname !== PageEnum.ORDER,
          })}
        >
          {!symbol && <span></span>}
          {symbol && location.pathname !== PageEnum.ORDER && (
            <Checkbox
              size="sm"
              defaultSelected
              color="primary"
              isSelected={multiCurrencyPositions}
              classNames={{
                wrapper: cn(
                  `after:bg-[#f4f4f5] hover:!bg-[transparent] text-[#000] before:border-[#f4f4f5]`,
                ),
                base: 'hover:!bg-[transparent]',
              }}
              onValueChange={(value) => {
                setMultiCurrencyPositions(value);
              }}
            >
              <span className={cn(`mr-0.5 text-[12px]`)}>
                {t('显示所有仓位')}
              </span>
            </Checkbox>
          )}

          {currentTab === ContractOrderStatusEnum.IN_POSITION && false && (
            <Button
              className="text-xs text-primary !p-0 !w-fit !bg-[transparent]"
              onClick={() => {
                if (closeAPositionWithOneClickLoaing) return;
                // 多币种平仓
                if (multiCurrencyPositions) {
                  if (isCloseOneClick) {
                    closeAPositionWithOneClickReq();
                    return;
                  }
                  openPrompt({
                    title: t('一键平仓'),
                    content: t(
                      '一键平仓将以市价平仓所有合约仓位，由于行情波动，一键平仓不一定成功。您确定要将所有仓位平仓吗？',
                    ),
                    checkTitle: t('不再提示'),
                    onCancel() {
                    },
                    onConfirm(isSelected) {
                      if (isSelected) {
                        setIsCloseOneClick(true);
                      }
                      closeAPositionWithOneClickReq();
                    },
                  });
                } else {
                  // 当前币种平仓
                  if (isCloseCurrent) {
                    closeAPositionWithOneClickReq({
                      symbolContractId: localContract?.id,
                    });
                    return;
                  }
                  openPrompt({
                    title: t('只平当前'),
                    content: t(
                      '只平当前将以市价平仓所筛选出的合约仓位，由于行情波动，平仓不一定成功。您确定要将当前仓位平仓吗？',
                    ),
                    checkTitle: t('不再提示'),
                    onCancel() {
                    },
                    onConfirm(isSelected) {
                      if (isSelected) {
                        setIsCloseCurrent(true);
                      }
                      closeAPositionWithOneClickReq({
                        symbolContractId: localContract?.id,
                      });
                    },
                  });
                }
              }}
            >
              {multiCurrencyPositions ? t('一键平仓') : t('只平当前')}
            </Button>
          )}
        </div>

        {isShowSift && (
          <div className="flex justify-between px-4 mt-3 mb-3">
            <div className="flex">
              {/* 订单过滤条件  */}
              {filterList.map((item: any) => {
                return (
                  <BaseSift
                    key={item?.title}
                    options={item.options}
                    onChange={item.onChange}
                    current={item.current}
                    title={item.title}
                  />
                );
              })}
            </div>
            <div>
              {/* 当前委托|历史成交 */}
              <AntDesignFilterFilled
                className="text-iconFontColor text-base cursor-pointer"
                onClick={onOpen}
              />
            </div>
          </div>
        )}
        {/*列表*/}
        <div className={cn(`px-4`, {
          ' min-h-[400px]': isMobile,
        })}>
          <BasePullList
            fetchFunction={getContractOrderListApi} // 请求数据函数
            params={params} // 请求参数
            setParams={setPageParams}
            manual={true}
            loadingDisplay={!isMobile}
            setLoadingDisplay={setLoadingDisplay}
            customEmpty={
              !contractAvailableFunds || (Number(contractAvailableFunds) === 0) ?
                (<div className="flex flex-col justify-center items-center">
                  <OrderEmptyStatus className="text-[#ADB4BC] dark:text-auxiliaryTextColor" width={44} height={44} />
                  <div className="mt-[2px] text-[16px]">
                    <span>{t('可用余额')}：</span>
                    <span className="mr-1">
              {RenderUtil.FormatAmount(
                Number(contractAvailableFunds),
                ProjectSetting.usdAccuracyLength,
                false,
              )}
            </span>
                    <span>
              {' ' + localContract?.coinAlias}
            </span>
                  </div>
                  <div className="text-auxiliaryTextColor text-xs text-center mt-3 mb-2">
                    {t('划转至合约账户')}
                  </div>
                  {(!contractAvailableFunds || (Number(contractAvailableFunds) === 0)) &&
                    <button
                      className="bg-[transparent] border-borderColor border-1 px-4 text-[14px] !rounded-md h-[28px] !py-0"
                      onClick={() => {
                        history.push(PageEnum.TRANSFER);
                      }}
                    >
                      {t('划转')}
                    </button>}
                </div>) : undefined
            }
            renderItem={(item: any, itemIndex: number) => {
              return (
                <ContractOrderCard
                  key={item?.id}
                  item={item}
                  itemIndex={itemIndex}
                  currentTab={currentTab}
                />
              );
            }}
          />
        </div>
      </div>
    );
  },
);
