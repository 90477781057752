import { Share } from '@/assets/icons/comm/Share';
import BaseConfirmModal from '@/components/base/baseConfirmModal';
import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { toast } from '@/layouts/components/ToastMessage';
import CloseOrder from '@/pages/order/index/components/CloseOrder';
import { ContractOrderStatusEnum } from '@/pages/order/index/components/ContractOrderList';
import ContractOrderPoster from '@/pages/order/index/components/ContractOrderPoster';
import OrderCardItem from '@/pages/order/index/components/OrderCardItem';
import UpdateTpTl from '@/pages/order/index/components/UpdateTpTl';
import useConstants from '@/pages/order/index/useConstants';
import {
  closeContractOrderApi,
  reverseSelectionToClosePositionApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { truncateNumber } from '@/utils/number';
import RenderUtil from '@/utils/RenderUtil';
import { calculateProfitLoss, calculateProfitLossRatio } from '@/utils/trade';
import { history } from '@@/core/history';
import { cn, Divider, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  item: any;
  currentTab: ContractOrderStatusEnum;
  itemIndex: number;
}

export default ({ item, currentTab, itemIndex }: IProps) => {
  // 基础定义
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const {
    currentUpClass,
    currentDownClass,
    currentUpBgClass,
    currentDownBgClass,
    reLoadOrder,
  } = useModel('system');
  const orderSocket = getSocketRowByOrderId(item.id);
  const socketData = getSocketRowByName(item.symbol);
  const { t } = useTranslation();
  // 海报弹窗
  const { isOpen: posterIsOpen, onClose: posterOnclose } = useDisclosure();

  // 撤销订单弹窗
  const { isOpen, onOpen, onClose } = useDisclosure();
  //撤销合约订单
  const { runAsync: closeContractOrderReq } = useReq(closeContractOrderApi, {
    manual: true,
    onSuccess: () => {
      reLoadOrder();
    },
  });
  const closeContractOrder = async (id: string) => {
    await closeContractOrderReq({ id });
  };
  // 平仓 的相关
  const {
    isOpen: closeIsOpen,
    onOpen: closeOnOpen,
    onOpenChange: closeIsOpenChange,
    onClose: closeOnClose,
  } = useDisclosure();

  //设置止盈止损
  const {
    isOpen: upTpTlIsOpen,
    onOpen: upTpTlOnOpen,
    onOpenChange: upTpTlIsOpenChange,
    onClose: upOnClose,
  } = useDisclosure();

  const { getStateMap } = useConstants();
  // 是否历史
  const isHistory = useMemo(() => {
    return currentTab === ContractOrderStatusEnum.HISTORY;
  }, [currentTab]);
  //是否委托
  const isCommission = useMemo(() => {
    return currentTab === ContractOrderStatusEnum.ON_COMMISSION;
  }, [currentTab]);

  const priceAccuracy = item?.priceAccuracy ?? orderSocket?.priceAccuracy ?? 6;

  // 盈亏
  const plAmount = isHistory
    ? item?.plAmount
    : // 计算持仓中的 盈亏
    calculateProfitLoss({
      marketPrice: socketData?.a || orderSocket?.currentPrice,
      openPrice: item?.endPrice,
      leverage: orderSocket?.lever ?? item?.lever,
      margin: item?.earnestMoney,
      isLong: item?.direction === 1, // 是否 开多
    });

  // 盈亏比
  const plRatio = isHistory
    ? item?.plRatio
    : // // 计算持仓中的开仓价 盈亏比
    calculateProfitLossRatio({
      marketPrice: socketData?.a || orderSocket?.currentPrice,
      openPrice: item?.endPrice,
      leverage: orderSocket?.lever ?? item?.lever,
      lotSize: item?.firstHand, // 一手等于
      isLong: item?.direction === 1, // 是否 开多
    });

  //已撤销
  const isRevoked = useMemo(() => {
    return item?.status === -1;
  }, [item]);

  //持仓中渲染item
  const renderPositionItem = [
    {
      label: t('开仓均价'),
      value: RenderUtil.FormatAmount(item?.endPrice, priceAccuracy, true),
    },
    {
      label: t('盈亏'),
      value: (
        <div
          className={`text-sm font-bold ${
            plAmount > 0 ? currentUpClass : currentDownClass
          }`}
        >
          {plAmount > 0 ? '+' : ''}
          {RenderUtil.FormatAmount(plAmount)}
        </div>
      ),
    },
    {
      label: t('盈亏比'),
      value: (
        <div
          className={`text-sm font-bold ${
            plRatio > 0 ? currentUpClass : currentDownClass
          }`}
        >
          {plRatio > 0 ? '+' : ''}
          {RenderUtil.formatAndCeilToTwoDecimals(plRatio)}%
        </div>
      ),
    },
    {
      label: t('持仓(张)'),
      value: RenderUtil.FormatAmount(item?.num, 0, false),
    },
    {
      label: t('保证金'),
      value: RenderUtil.FormatAmount(item?.earnestMoney, priceAccuracy),
    },
    {
      label: t('当前价'),
      value: RenderUtil.FormatAmount(
        socketData?.a || orderSocket?.currentPrice,
        priceAccuracy,
        true,
      ),
    },
    {
      label: t('强平价格'),
      value: item?.forcedLiquidationPrice
        ? RenderUtil.FormatAmount(
          item?.forcedLiquidationPrice,
          orderSocket?.priceAccuracy ?? 2,
          false,
        )
        : '--',
    },
    {
      label: t('持仓/委托/可平(张)'),
      value: (
        <span>
          {RenderUtil.FormatAmount(item?.num, 0, false)}/
          {RenderUtil.FormatAmount(item?.entrustPositionNum, 0, false)}/
          {RenderUtil.FormatAmount(item?.availablePositionNum, 0, false)}
        </span>
      ),
    },
  ];
  //非持仓渲染item
  const renderNotPositionItem = [
    {
      label: t('购买数量(张)'),
      value: truncateNumber(item?.num, 0),
      condition: !isHistory,
    },
    {
      label: t('结算价格'),
      value: RenderUtil.FormatAmount(
        isCommission ? item?.limitPrice : item?.startPrice,
        priceAccuracy,
        true,
      ),
      condition: !isHistory,
    },
    {
      label: t('类型'),
      value: (
        <span>
          {item?.type === 1 && t('市价')}
          {item?.type === 2 && t('限价')}
        </span>
      ),
    },
    {
      label: t('成交均价/委托总量(张)'),
      value: (
        <span>
          {RenderUtil.FormatAmount(item?.completePrice, priceAccuracy, true)}/
          {item?.num}
        </span>
      ),
      condition: isHistory,
    },
    {
      label: t('保证金'),
      value: RenderUtil.FormatAmount(item?.earnestMoney, 2, false),
      condition: isHistory,
    },
    {
      label: t('手续费'),
      value: RenderUtil.FormatAmount(item?.commission, 2, false),
      condition: isHistory,
    },
    {
      label: t('状态'),
      value: getStateMap(item?.status),
      condition: !isHistory,
    },
    {
      label: t('盈亏比例'),
      value: (
        <div
          className={`text-sm font-bold ${
            plRatio > 0 ? currentUpClass : currentDownClass
          }`}
        >
          {plRatio ? (
            <>
              {plRatio > 0 ? '+' : ''}
              {RenderUtil.formatAndCeilToTwoDecimals(plRatio) + '%'}
            </>
          ) : (
            '--'
          )}
        </div>
      ),

      condition: !isCommission && !isRevoked,
    },
    {
      label: t('已实现盈亏'),
      value: (
        <div
          className={`text-sm font-bold ${
            plAmount > 0 ? currentUpClass : currentDownClass
          }`}
        >
          {plAmount ? (
            <>
              {plAmount > 0 ? '+' : ''}
              {RenderUtil.FormatAmount(plAmount)}
            </>
          ) : (
            '--'
          )}
        </div>
      ),
      condition: !isCommission && !isRevoked,
    },
  ];
  //一键反向平仓
  const { refreshWallet } = useModel('user');
  const { run: reverseSelectionToClosePositionReq, loading } = useReq(
    reverseSelectionToClosePositionApi,
    {
      manual: true,
      loadingDefault: false,
      onSuccess() {
        toast.success(t('操作成功'));
        onClose();
        refreshWallet();
        reLoadOrder();
      },
    },
  );

  const { openPrompt } = useGlobalPrompt();
  // 反手提示是否关闭-缓存
  const [backhandTips, setBackhandTips] = useLocalStorage({
    key: CacheEnum.APP_REVERSE_TIPS,
    defaultValue: false,
  });

  // 根据后端状态过滤
  const siftMap = useMemo(() => {
    return {
      [ContractOrderStatusEnum.ON_COMMISSION]: ['1'],
      [ContractOrderStatusEnum.IN_POSITION]: ['2'],
      [ContractOrderStatusEnum.HISTORY]: ['0', '-1', '-2', '-3'],
    };
  }, []);

  return (
    <>
      {
        siftMap[currentTab].includes(item.status + '') && <>
          <OrderCardItem
            item={item}
            classNames={{
              base: '!mb-0',
              divider: '!hidden',
            }}
            itemIndex={itemIndex}
            infoArray={
              currentTab === ContractOrderStatusEnum.IN_POSITION
                ? renderPositionItem
                : renderNotPositionItem
            }
            cardWrapper={
              currentTab === ContractOrderStatusEnum.IN_POSITION
                ? 'gridCol3'
                : 'flexBetween'
            }
            title={
              <div className="flex justify-start flex-wrap items-center">
                <div className="text-titleColor font-[500] flex justify-center items-center">
                  {item?.direction === 1 && (
                    <span
                      className={cn(
                        'text-white font-normal ml-1 rounded-[4px] text-xs py-0.5 px-1',
                        currentUpBgClass,
                      )}
                    >
                  {currentTab === ContractOrderStatusEnum.IN_POSITION &&
                    t('多')}
                      {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                        item?.positionSwitchType === 0 &&
                        t('开多')}
                      {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                        item?.positionSwitchType === 1 &&
                        t('平多')}
                </span>
                  )}
                  {item?.direction === 2 && (
                    <span
                      className={cn(
                        'text-white font-normal bg-font-normal rounded-[4px] text-xs py-0.5 px-1',
                        currentDownBgClass,
                      )}
                    >
                  {currentTab === ContractOrderStatusEnum.IN_POSITION &&
                    t('空')}
                      {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                        item?.positionSwitchType === 0 &&
                        t('开空')}
                      {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                        item?.positionSwitchType === 1 &&
                        t('平空')}
                </span>
                  )}
                  <span className="ml-2 font-bold">{item?.name}</span>
                </div>

                <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
              {t('永续')}
            </span>

                {item?.earnestMode !== null && (
                  <>
                <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
                  {item?.earnestMode === 1 ? t('全仓') : t('逐仓')}
                </span>
                  </>
                )}

                {item?.lever !== null && (
                  <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
                {orderSocket?.lever ?? item?.lever}x
              </span>
                )}
              </div>
            }
            icon={
              isCommission ? null : (
                <Share
                  className="text-auxiliaryTextColor flex items-center text-xs"
                  onClick={(e) => {
                    history.push(PageEnum.CONTRACT_SHARE, {
                      curItem: item,
                      currentTab,
                    });
                    e.stopPropagation();
                  }}
                />
              )
            }
            onClickCard={() => {
              history.push(`${PageEnum.CONTRACT_ORDER_DETAIL}/${item.id}`);
            }}
          />
          <div className="flex justify-between items-center mt-1">
            {isHistory && (
              // 历史的需要用平仓时间
              <span className="text-auxiliaryTextColor text-xs">
            {RenderUtil.formatDate(item?.completeTime, 'YYYY-MM-DD HH:mm:ss')}
          </span>
            )}

            <div className="flex gap-3 justify-between w-full">
              {item?.status === 1 && (
                <div
                  className="!bg-backgroundAuxiliaryColor flex-1 text-[12px] px-2 py-2 min-h-[32px] rounded-md flex items-center justify-center text-center leading-[10px] !break-words    !text-titleColor  "
                  onClick={() => {
                    onOpen();
                  }}
                >
                  {t('撤销')}
                </div>
              )}

              {item?.status === 2 && (
                <div
                  className="!bg-backgroundAuxiliaryColor flex-1 text-[12px] px-2 py-2 min-h-[32px] rounded-md flex items-center justify-center text-center leading-[10px] break-words    text-titleColor cursor-pointer"
                  onClick={(event) => {
                    upTpTlOnOpen();
                    event.stopPropagation();
                  }}
                >
                  {t('止盈/止损')}
                </div>
              )}

              {item?.status === 2 && (
                <div
                  className="!bg-backgroundAuxiliaryColor flex-1 text-[12px] px-2 py-2 min-h-[32px] rounded-md flex items-center justify-center text-center leading-[10px] break-words    text-titleColor cursor-pointer"
                  onClick={(event) => {
                    if (backhandTips) {
                      reverseSelectionToClosePositionReq({ id: item?.id });
                      return;
                    }
                    if (loading) return;
                    openPrompt({
                      title: t('反手说明'),
                      content: t(
                        '您的仓位将进行市价平仓，并反向开仓相同数量。如资金不足以开仓相同数量或由于行情波动，反向开仓未必100%成功。您确定要一键反手吗？',
                      ),
                      checkTitle: t('不再提示'),
                      onCancel() {
                      },
                      onConfirm(isSelected) {
                        if (isSelected) {
                          setBackhandTips(true);
                        }
                        reverseSelectionToClosePositionReq({ id: item?.id });
                      },
                    });
                  }}
                >
                  {t('一键反手')}
                </div>
              )}

              {item?.status === 2 && (
                <div
                  className="!bg-backgroundAuxiliaryColor flex-1 text-[12px] px-2 py-2 min-h-[32px] rounded-md flex items-center justify-center text-center leading-[10px] break-words text-titleColor"
                  onClick={() => {
                    closeOnOpen();
                  }}
                >
                  {t('平仓')}
                </div>
              )}
            </div>
          </div>
          <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
        </>
      }


      {/* // 分享 */}
      <ContractOrderPoster
        isOpen={posterIsOpen}
        currentTab={currentTab}
        onClose={posterOnclose}
        curItem={item}
      />

      {upTpTlIsOpen && (
        <UpdateTpTl
          isOpen={upTpTlIsOpen}
          onOpen={upTpTlOnOpen}
          onOpenChange={upTpTlIsOpenChange}
          onClose={upOnClose}
          curItem={item}
        />
      )}

      {/* 平仓 */}
      {closeIsOpen && (
        <CloseOrder
          isOpen={closeIsOpen}
          onOpen={closeOnOpen}
          onClose={closeOnClose}
          curItem={item}
          onOpenChange={closeIsOpenChange}
        />
      )}
      <BaseConfirmModal
        title={t('请确认')}
        isOpen={isOpen}
        onClose={onClose}
        onOk={() => {
          onClose();
          if (item?.status === 1) {
            closeContractOrder(item?.id as any);
          } else {
            reLoadOrder();
          }
        }}
      >
        <p>{t('请确认是否撤销订单！')}</p>
      </BaseConfirmModal>
    </>
  );
};
