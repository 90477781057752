import {
  MarginModeEnum,
  TradeTypeEnum,
  TradingDirectionEnum,
} from '@/enums/businessEnum';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

export default () => {
  const { appInfo } = useModel('appInfo');

  // 这个配置是否 开启逐仓
  const { EARNEST_MODE_CONTROL } = appInfo || {};

  /*---------------------筛选状态--------------------------------*/
  //获取国际化函数
  const { t } = useTranslation();

  // 方向筛选
  const directionFilterOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('买涨'),
      value: TradingDirectionEnum.LONG,
    },
    {
      text: t('买跌'),
      value: TradingDirectionEnum.SHORT,
    },
  ];
  //交易类型 限价/市价
  const tradeTypeOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('市价'),
      value: TradeTypeEnum.MARKET,
    },
    {
      text: t('限价'),
      value: TradeTypeEnum.LIMIT,
    },
  ];

  //保证金模式 全仓/逐仓
  const marginModeOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('全仓'),
      value: MarginModeEnum.FULL,
    },
    {
      text: t('逐仓'),
      value: MarginModeEnum.SEGMENTED,
      isShow: EARNEST_MODE_CONTROL,
    },
  ].filter((i) => i?.isShow !== false);
  //订单卡片根据后端状态映射中文
  const getStateMap = (status: number) => {
    let value = '';
    switch (status) {
      case -2:
        value = t('已强平');
        break;
      case -1:
        value = t('已撤销');
        break;
      case 0:
        value = t('已结算');
        break;
      case 1:
        value = t('委托中');
        break;
      case 2:
        value = t('持仓中');
        break;
    }
    return value;
  };

  //申购状态
  const SubscribeEnum: any = {
    0: t('待审核'),
    1: t('审核通过'),
    2: t('审核失败'),
    3: t('进行中'),
    4: t('已结算'),
    5: t('结算失败'),
  };

  return {
    SubscribeEnum,
    directionFilterOption,
    tradeTypeOption,
    marginModeOption,
    getStateMap,
  };
};
