import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseModal from '@/components/base/baseModal';
import BaseTabs from '@/components/base/baseTabs';
import ValueDisplay from '@/components/display/displayValueDisplay';
import {
  closingContractOrderApi,
  reverseSelectionToClosePositionApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import { truncateNumber } from '@/utils/number';
import RenderUtil from '@/utils/RenderUtil';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

/**
 *  平仓 CloseOrder
 */
export default function CloseOrder(props: any) {
  /**
   * curItem 当前的股票订单
   */

  const { isOpen, curItem, onClose, curStock } = props || {};
  const { t } = useTranslation();
  const { getSocketRowByName } = useModel('socket');
  const { reLoadOrder } = useModel('system');
  const soketData = getSocketRowByName(curItem?.symbol ?? curStock?.symbol);
  //刷新钱包
  const { refreshWallet } = useModel('user');
  const { localContract } = useModel('contract');
  // 价格精度
  const priceAccuracy = curItem?.priceAccuracy ?? 6;
  // 交易精度，后台控制值，输入框
  const transactionAccuracy = curItem?.transactionAccuracy ?? 6;
  // 红绿色
  const { currentUpClass, currentDownClass } = useModel('system');
  // 市价/限价
  const [marketPriceLimitPrice] = useState([
    {
      text: t('市价'),
      value: '1',
    },
    {
      text: t('限价'),
      value: '2',
    },
  ]);
  // 是否市价平仓
  const [isMarketClose, setIsMarketClose] = useState('1');
  // 平仓数量
  const [num, setNum] = useState<any>('');
  // 平仓价格
  const [price, setPrice] = useState<any>('');
  // 价格精度
  const priceAccuracyLength = useMemo(() => {
    return curItem?.priceAccuracy ?? 6;
  }, [curItem]);
  // 仓位选择配置
  const positionList = useMemo(() => {
    return [25, 50, 75, 100];
  }, []);
  // 当前平仓百分比
  const [currentPosition, setCurrentPosition] = useState(25);
  const handleSliderChange = (item: any) => {
    setNum(item * curItem?.availablePositionNum * 0.01);
    setCurrentPosition(item);
  };

  //完全平仓
  const { runAsync: closeContractOrderReq, loading } = useReq(
    (e: any) => {
      return closingContractOrderApi(e);
    },
    {
      loadingDefault: false,
      manual: true,
      onSuccess: () => {
        onClose();
        refreshWallet();
        // reLoadOrder();
      },
    },
  );

  //一键反向平仓
  const { run: reverseSelectionToClosePositionReq, loading: loading2 } = useReq(
    reverseSelectionToClosePositionApi,
    {
      manual: true,
      loadingDefault: false,
      onSuccess() {
        onClose();
        refreshWallet();
        reLoadOrder();
      },
    },
  );
  // 手续费
  const commission = useMemo(() => {
    return localContract?.commission;
  }, [localContract]);

  // 手续费计算
  const premium = useMemo(() => {
    // 找到 杠杆对应的 保证金
    const find = localContract?.commisionList?.find(
      (item: any) => +item?.lever === +curItem?.lever,
    );
    // 市场价格×杠杆对应手续费×数量/100
    // if (find) return (soketData.a * find?.commission * num) / 100;
    return (num * localContract?.earnestMoney * find?.commission) / 100;
  }, [localContract, commission, curItem, soketData, num]);

  // 设置默认值
  useEffect(() => {
    if (isOpen) {
      setNum(curItem?.availablePositionNum);
    }
  }, [isOpen]);

  const onSubmit = async () => {
    const n = Number(truncateNumber(num, transactionAccuracy));
    // 向下保留transactionAccuracy小数位数
    if (n > curItem?.num) {
      return toast.warning(t('不能超过仓位数量'));
    }
    closeContractOrderReq({
      id: curItem.id,
      type: isMarketClose,
      num: n,
      isFull: true,
      limitPrice: isMarketClose === '1' ? soketData?.a : price,
    });
  };

  // 币种数量计算
  const coinNum = useMemo(() => {
    return (num * curItem?.symbolEarnestMoney) / curItem.endPrice;
  }, [curItem, num]);

  // 市价预估盈亏
  const estimatedCost = useMemo(() => {
    let _profit = 0;
    if (curItem?.direction === 1) {
      _profit = (soketData.a - curItem.endPrice) * curItem?.firstHand * coinNum;
    } else {
      _profit = (curItem.endPrice - soketData.a) * curItem?.firstHand * coinNum;
    }
    return {
      price: soketData.a,
      profit: _profit,
      plRatio: (_profit / curItem?.earnestMoney) * 100,
    };
  }, [curItem, soketData, coinNum]);

  // 限价预估盈亏
  const estimatedProfitAndLossAtLimitPrice = useMemo(() => {
    let _profit = 0;
    if (curItem?.direction === 1) {
      _profit = (price - curItem.endPrice) * curItem?.firstHand * coinNum;
    } else {
      _profit = (curItem.endPrice - price) * curItem?.firstHand * coinNum;
    }
    return {
      price,
      profit: _profit,
      plRatio: (_profit / curItem?.earnestMoney) * 100,
    };
  }, [curItem, soketData, coinNum, price]);

  // 预计盈亏
  const estimatedProfitAndLoss = useMemo(() => {
    return isMarketClose === '1'
      ? estimatedCost
      : estimatedProfitAndLossAtLimitPrice;
  }, [isMarketClose, estimatedCost, estimatedProfitAndLossAtLimitPrice]);

  // 渲染国际化引擎(主要是为了能正确解析jsx)
  const renderI18Template = (
    template: string,
    params: any,
  ): { __html: string } => {
    const renderedTemplate = template.replace(/{{(\w+)}}/g, (match, p1) => {
      const paramValue = params[p1];
      if (typeof paramValue === 'string' || typeof paramValue === 'number') {
        return String(paramValue);
      } else if (React.isValidElement(paramValue)) {
        return ReactDOMServer.renderToStaticMarkup(paramValue);
      } else {
        return '';
      }
    });

    return { __html: renderedTemplate };
  };
  useUpdateEffect(() => {
    if (isMarketClose === '2') {
      setPrice(soketData?.a);
    }
  }, [isMarketClose]);

  return (
    <BaseModal
      isOpen={isOpen}
      hideCloseButton
      onClose={onClose}
      isShowCloseButton
    >
      <div className="pb-10 text-foreground px-4  flex flex-col justify-between max-h-[80vh] overflow-y-auto  sm:pb-8">
        <div className="pb-6 text-xs">
          <div className="pb-4">
            <div className="flex justify-between items-center">
              <BaseTabs
                options={[{ text: t('平仓'), value: '1' }]}
                value={'1'}
                tabsPropsProps={{
                  classNames: {
                    cursor: 'hidden',
                  },
                }}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <BaseTabs
              value={isMarketClose}
              variant={'solid'}
              onChange={setIsMarketClose}
              options={marketPriceLimitPrice}
            />
            <div className="flex justify-between  items-center">
              <div className=" text-auxiliaryTextColor">{t('名称')}</div>
              <div
                className={`font-bold ${
                  curItem?.direction === 1 ? currentUpClass : currentDownClass
                }`}
              >
                {curItem?.name}
              </div>
            </div>

            <div className="flex justify-between ">
              <div className=" text-auxiliaryTextColor">
                {t('最新价格')} ({ProjectSetting.APP_DEFAULT_CURRENCY})
              </div>
              <div className=" font-bold">
                <ValueDisplay
                  value={soketData?.a}
                  len={priceAccuracy}
                  isSubLen={false}
                />
              </div>
            </div>

            <div className="flex justify-between ">
              <div className=" text-auxiliaryTextColor">
                {t('开仓价格')} ({ProjectSetting.APP_DEFAULT_CURRENCY})
              </div>
              <div className=" font-bold">
                {RenderUtil.FormatAmount(
                  curItem?.endPrice,
                  priceAccuracy,
                  true,
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className=" text-auxiliaryTextColor">
                {t('手续费')} ({ProjectSetting.APP_DEFAULT_CURRENCY})
              </div>
              <div className=" font-bold">
                {RenderUtil.FormatAmount(premium!, 2)}{' '}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className=" text-auxiliaryTextColor">
                {t('仓位数量(张)')}
              </div>
              <div className=" font-bold">
                {truncateNumber(curItem?.availablePositionNum, 0)}
                {' ' + t('张')}
              </div>
            </div>

            {isMarketClose === '2' && (
              <div className="flex flex-col gap-2">
                <div className="font-bold text-xs">{t('平仓价格')}</div>
                <BaseInputNumber
                  value={price}
                  step={1 / Math.pow(10, priceAccuracyLength ?? 0)}
                  len={priceAccuracyLength}
                  onChange={(value: SetStateAction<number>) => {
                    setPrice(value);
                  }}
                />
              </div>
            )}

            {/*<div className="flex flex-col gap-1">*/}
            {/*    <div className="font-bold text-xs">{t('平仓数量')}</div>*/}
            {/*    <BaseInputNumber*/}
            {/*        value={num}*/}
            {/*        step={1 / Math.pow(10, transactionAccuracy ?? 0)}*/}
            {/*        len={transactionAccuracy}*/}
            {/*        onChange={(value: SetStateAction<number>) => {*/}
            {/*            setNum(value);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    /!*<div className="flex w-full justify-between flex-wrap  gap-x-[12px] mt-2">*!/*/}
            {/*    /!*    {positionList?.map((item: number) => (*!/*/}
            {/*    /!*        <div*!/*/}
            {/*    /!*            key={item}*!/*/}
            {/*    /!*            className={`border-1 cursor-pointer text-center py-1 flex-1  text-xs  border-backgroundAuxiliaryColor rounded-md ${*!/*/}
            {/*    /!*                +currentPosition === +item ? '!border-backContrastColor' : ''*!/*/}
            {/*    /!*            }`}*!/*/}
            {/*    /!*            onClick={() => {*!/*/}
            {/*    /!*                handleSliderChange(item)*!/*/}
            {/*    /!*            }}*!/*/}
            {/*    /!*        >*!/*/}
            {/*    /!*            {item}%*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    ))}*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
            {estimatedProfitAndLoss.price &&
              estimatedProfitAndLoss.price !== '0' && (
                <div
                  className="text-auxiliaryTextColor bg-backgroundAuxiliaryColor rounded-md p-2 "
                  dangerouslySetInnerHTML={renderI18Template(
                    t(
                      '仓位将以最新成交价平仓平仓{{newPrice}}张，预期盈利为{{profit}} USDT(回报率: {{plRatio}})',
                    ),
                    {
                      newPrice: RenderUtil.FormatAmount(
                        estimatedProfitAndLoss.price,
                        priceAccuracy,
                      ),
                      profit: (
                        <span
                          className={`${
                            estimatedProfitAndLoss?.profit > 0
                              ? currentUpClass
                              : currentDownClass
                          }`}
                        >
                          {estimatedProfitAndLoss?.profit > 0 ? '+' : ''}
                          {RenderUtil.FormatAmount(
                            estimatedProfitAndLoss?.profit,
                            priceAccuracy,
                          )}
                        </span>
                      ),
                      plRatio: (
                        <span
                          className={`${
                            estimatedProfitAndLoss?.plRatio > 0
                              ? currentUpClass
                              : currentDownClass
                          }`}
                        >
                          {estimatedProfitAndLoss?.plRatio > 0 ? '+' : ''}{RenderUtil.FormatAmount(estimatedProfitAndLoss?.plRatio, 2)}%
                        </span>
                      ),
                    },
                  )}
                ></div>
              )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Button
            spinner={<LoadingSvg />}
            isLoading={loading}
            onClick={onSubmit}
            className="mainColorButton w-full !py-[12px] rounded-md "
          >
            <div className="text-xs">
              <div>{t('确认')}</div>
            </div>
          </Button>
          {/*<Button*/}
          {/*    spinner={<LoadingSvg/>}*/}
          {/*    isLoading={loading2}*/}
          {/*    onClick={() => {*/}
          {/*        reverseSelectionToClosePositionReq({id: curItem?.id})*/}
          {/*    }}*/}
          {/*    className="bg-backgroundAuxiliaryColor  w-full !py-[12px] rounded-md "*/}
          {/*>*/}
          {/*    <div className="text-xs">*/}
          {/*        <div>{t('一键反向')}</div>*/}
          {/*    </div>*/}
          {/*</Button>*/}
        </div>
      </div>
    </BaseModal>
  );
}
