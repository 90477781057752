import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import BaseModal from '@/components/base/baseModal';
import { useModel } from '@@/exports';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, ScrollShadow, useDisclosure } from '@nextui-org/react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';

/**
 * 用于实现一个具有下拉菜单功能的过滤框。
 * @param props 组件接收的属性对象，包含以下字段：
 * - options: 提供给下拉菜单的选项数组，每个选项包含`text`和`value`属性。
 * - onChange: 当选项被选择时调用的回调函数，接收选中的值作为参数。
 * - defaultText: 默认显示在过滤框中的文本。
 * @returns 渲染的React元素，包括一个点击可打开下拉菜单的文本框和一个下拉菜单。
 */
export default function BaseSift({
                                   options,
                                   onChange,
                                   current,
                                   title,
                                 }: IProps) {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isFirst, setIsFirst] = useState(true);
  const downMenuClick = (value: any) => {
    if (value?.text === current?.text) {
      onClose();
      return;
    }
    onChange(value);
    setIsFirst(false);
    onClose();
  };
  const { t } = useTranslation();

  const { isMobile } = useModel('system');
  const renderTitle = useMemo(() => {
    return isFirst && current?.value === undefined ? title : current?.text;
  }, [current]);
  return (
    <>
      {/*PC端使用下拉框*/}
      {!isMobile && (
        <Dropdown
          classNames={{
            content: 'bg-background',
          }}
        >
          <DropdownTrigger>
            <span className="filterBox rounded-md">
              <div className="cursor-pointer">
                {renderTitle}
              </div>
              <TeenyiconsDownSolid
                className="ml-1"
                width="0.5em"
                height="0.8em"
              />
            </span>
          </DropdownTrigger>
          <DropdownMenu
            className="text-backContrastColor"
            variant="flat"
            closeOnSelect
            disallowEmptySelection
            selectionMode="single"
            selectedKeys={[current?.value + '']}
          >
            {options?.map((item) => {
              return (
                <DropdownItem
                  onClick={() => {
                    downMenuClick(item);
                  }}
                  key={item?.value + ''}
                >
                  {item.text}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      )}

      {/*移动端响应抽屉*/}
      {isMobile && (
        <span
          className="filterBox rounded-md"
          onClick={() => {
            if (isMobile) {
              onOpen();
            }
          }}
        >
          <div className="cursor-pointer">
           {renderTitle}
          </div>
          <TeenyiconsDownSolid className="ml-1" width="0.5em" height="0.8em" />
        </span>
      )}

      {/*移动端显示抽屉*/}
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        classNames={{ title: 'text-[17px] font-semibold' }}
        title={title}
      >
        <div>
          <ScrollShadow hideScrollBar className="max-h-[60vh]">
            <div className="mt-2 ">
              {options?.map((item) => (
                <div
                  key={item.value}
                  onClick={() => {
                    downMenuClick(item);
                  }}
                  className={`flex items-center px-4 justify-between text-foreground  border-t-[1px] border-backgroundAuxiliaryColor text-[16px]   py-4`}
                >
                  <span>{item.text}</span>
                  {current?.value === item.value &&
                    <IconParkSolidCorrect className="text-primary" />
                  }
                </div>
              ))}
            </div>
          </ScrollShadow>

          <div
            onClick={onClose}
            className={`text-center  border-t-[8px] border-backgroundAuxiliaryColor  text-[16px] text-foreground  py-4 `}
          >
            <span>{t('取消')}</span>
          </div>
        </div>

      </BaseModal>
    </>
  );
}

// 定义组件接收的props接口
interface IProps {
  options: {
    text: string;
    value?: any;
  }[];
  onChange: (value: { text: string; value?: any }) => void | Dispatch<SetStateAction<{
    text: string,
    value: undefined
  }>>;
  current:
    | {
    text: string;
    value?: any;
  }
    | any;
  title: string;
}
