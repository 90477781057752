import { SaveIcon } from '@/assets/icons/comm/SaveIcon';
import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import BaseModal from '@/components/base/baseModal';
import ValueDisplay from '@/components/display/displayValueDisplay';
import PageEnum from '@/enums/pageEnum';
import { ContractOrderStatusEnum } from '@/pages/order/index/components/ContractOrderList';
import RenderUtil from '@/utils/RenderUtil';
import { history, useModel } from '@@/exports';
import { cn, Image } from '@nextui-org/react';
import * as htmlToImage from 'html-to-image';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ isOpen, onClose, curItem, currentTab }: any) => {
  // 基础定义
  const { t } = useTranslation();
  const { user, getContractProfitAndLoss } = useModel('user') || {};
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const { appInfo } = useModel('appInfo');
  const soketData = getSocketRowByName(curItem?.symbol);
  const {
    currentUpClass,
    currentDownClass,
    currentUpBgClass,
    currentDownBgClass,
  } = useModel('system');
  const posterRef = useRef<HTMLDivElement>(null);
  // 价格精度
  const priceAccuracy = curItem?.priceAccuracy ?? 6;
  const orderSocket = getSocketRowByOrderId(curItem.id);
  const isHistory = useMemo(() => {
    return currentTab === ContractOrderStatusEnum.HISTORY;
  }, [currentTab]);
  // 盈亏比
  const plRatio = isHistory
    ? curItem?.plRatio
    : getContractProfitAndLoss(curItem?.id)?.plRatio || curItem?.plRatio;

  // 盈亏值
  const plAmount = isHistory
    ? curItem?.plRatio
    : getContractProfitAndLoss(curItem?.id)?.plAmount || curItem?.plAmount;

  // 保存海报

  const downloadPoster = async () => {
    const posterElement = posterRef.current;
    if (!posterElement) return;

    // 检测是否为 Safari 浏览器
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // 调用 htmlToImage 生成 PNG
    htmlToImage
      .toPng(posterElement, { cacheBust: true, backgroundColor: '#000' })
      .then(async (dataUrl) => {
        // 如果是 Safari，延迟执行以等待资源加载
        if (isSafari) {
          // eslint-disable-next-line no-promise-executor-return
          await new Promise((resolve) => setTimeout(resolve, 1)); // 延迟 1000ms
        }

        requestAnimationFrame(() => {
          const a = document.createElement('a');
          a.href = dataUrl;
          a.download = 'PositionsSharing.png';
          a.click();
        });
      })
      .catch((error) => {
        console.error('Error generating image:', error);
      });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title={t('分享')}
      isShowCloseButton
      onClose={onClose}
      classNames={{
        modal: 'border-none',
      }}
    >
      <div className="px-4 pb-[30px] pt-[13px] ">
        <div
          className="rounded-md"
          ref={posterRef}
          style={{
            backgroundImage: `url('${require('@/assets/img/shareBg.png')}')`,
            backgroundRepeat: 'no-repeat',
            backgroundClip: 'content-box',
            backgroundSize: '100% 100%',
          }}
        >
          <div className="px-4 py-[24px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div>
                  {user?.avatar ? (
                    <Image
                      className="w-[28px] h-[28px] flex-shrink-0 rounded-full object-cover"
                      src={user?.avatar}
                    />
                  ) : (
                    <Image
                      className="w-[28px] h-[28px] flex-shrink-0 rounded-full object-cover"
                      src={require('@/assets/img/defaultAvatar.png')}
                    />
                  )}
                </div>
                <div className="text-[#fff] text-base">{user?.name}</div>
              </div>
              <span>
                {isHistory ? (
                  // 历史的需要用平仓时间
                  <span className="text-auxiliaryTextColor text-xs">
                    {RenderUtil.formatDate(curItem?.completeTime,"YYYY-MM-DD HH:mm:ss")}
                  </span>
                ) : (
                  <span className="text-auxiliaryTextColor text-xs">
                    {RenderUtil.formatDate(curItem?.createTime,"YYYY-MM-DD HH:mm:ss")}
                  </span>
                )}
              </span>
            </div>
            <div className="flex justify-start flex-wrap items-center mt-[60px]">
              <div className="text-[#fff] font-[500] flex justify-center items-center">
                <span className="text-[18px] font-bold">{curItem?.name}</span>
              </div>
            </div>
            <div
              className="flex items-center gap-1 pt-2"
              style={{
                fontSize: '12px',
              }}
            >
              {curItem?.direction === 1 && (
                <div
                  className={cn(
                    'py-1 px-1 text-[#fff]  rounded-[4px]',
                    currentUpBgClass,
                  )}
                >
                  {currentTab === ContractOrderStatusEnum.IN_POSITION &&
                    t('多')}
                  {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                    curItem?.positionSwitchType === 0 &&
                    t('开多')}
                  {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                    curItem?.positionSwitchType === 1 &&
                    t('平多')}
                </div>
              )}
              {curItem?.direction === 2 && (
                <div
                  className={cn(
                    'py-1 relative text-[#fff]  rounded-[4px] px-1',
                    currentDownBgClass,
                  )}
                >
                  {currentTab === ContractOrderStatusEnum.IN_POSITION &&
                    t('空')}
                  {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                    curItem?.positionSwitchType === 0 &&
                    t('开空')}
                  {currentTab !== ContractOrderStatusEnum.IN_POSITION &&
                    curItem?.positionSwitchType === 1 &&
                    t('平空')}
                </div>
              )}
              <div className="py-1 bg-[#1b1b1b] text-[#fff]  rounded-[4px] px-1">
                {t('永续')}
              </div>

              {curItem?.earnestMode !== null && (
                <>
                  <div className="py-1 bg-[#1b1b1b]  text-[#fff] rounded-[4px] px-1">
                    {curItem?.earnestMode === 1 ? t('全仓') : t('逐仓')}
                  </div>
                </>
              )}

              {curItem?.lever !== null && (
                <div className="py-1 bg-[#1b1b1b]  text-[#fff] rounded-[4px] px-1">
                  {orderSocket?.lever ?? curItem?.lever}x
                </div>
              )}
            </div>
            <div
              className={`text-[40px] mt-[32px] font-bold ${
                plRatio > 0 ? currentUpClass : currentDownClass
              }`}
            >
              {plRatio > 0 ? '+' : ''}
              {RenderUtil.formatAndCeilToTwoDecimals(plRatio)}%
            </div>

            <div
              className={`text-[20px]  font-bold ${
                plAmount > 0 ? currentUpClass : currentDownClass
              }`}
            >
              {plAmount > 0 ? '+' : ''}
              {RenderUtil.FormatAmount(plAmount, 2)}
            </div>

            <div className="mt-[24px] flex items-center gap-8">
              <div className="flex flex-col text-xs">
                <span className="text-[#86909C]">{t('开仓均价')}</span>
                <span className="text-[#fff] pt-2">
                  {RenderUtil.FormatAmount(
                    curItem?.endPrice,
                    priceAccuracy,
                    true,
                  )}
                </span>
              </div>
              {!curItem?.completePrice && (
                <div className="flex flex-col text-xs">
                  <span className="text-[#86909C]">{t('标记价格')}</span>
                  <div className="pt-2">
                    <ValueDisplay
                      value={soketData?.a}
                      len={priceAccuracy}
                      isSubLen={false}
                    />
                  </div>
                </div>
              )}
              {curItem?.completePrice && (
                <div className="flex flex-col text-xs">
                  <span className="text-[#86909C]">{t('平仓价格')}</span>
                  <div className="pt-2">
                    {RenderUtil.FormatAmount(
                      curItem?.completePrice,
                      priceAccuracy,
                      true,
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center  pt-6 gap-1">
              {appInfo?.APP_BASE_LOGO ? (
                <img
                  src={appInfo?.APP_BASE_LOGO}
                  className="w-[30px]  cursor-pointer  box-content"
                  alt="logo"
                  onClick={() => {
                    history.push(PageEnum.MINE);
                  }}
                />
              ) : (
                <BasilUserSolid
                  className=" text-2xl text-titleColor"
                  onClick={() => {
                    history.push(PageEnum.MINE);
                  }}
                />
              )}
              <span className="text-xs text-[#fff]">
                {appInfo?.COMPANY_NAME}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center  gap-[40px] mt-[24px] px-[23px]">
          <div
            className="flex flex-col justify-center items-center gap-2"
            onClick={() => {
              downloadPoster();
            }}
          >
            <div className="flex  justify-center items-center w-[40px] h-[40px] rounded-full bg-backgroundAuxiliaryColor">
              <SaveIcon className="text-backContrastColor" />
            </div>
            <span className="text-[12px]">{t('保存')}</span>
          </div>
          <div
            className="flex flex-col justify-center items-center gap-2"
            onClick={() => {
              downloadPoster();
              window.open('https://web.telegram.org');
            }}
          >
            <div className="flex  justify-center items-center w-[40px] overflow-hidden h-[40px] rounded-full bg-backgroundAuxiliaryColor">
              <img
                src={require('@/assets/img/tg.png')}
                className="w-full h-full"
                alt=""
              />
            </div>
            <span className="text-[12px]">telegram</span>
          </div>
          <div
            className="flex flex-col justify-center items-center gap-2"
            onClick={() => {
              downloadPoster();
              window.open('https://twitter.com/intent/tweet?text=poster');
            }}
          >
            <div className="flex  justify-center items-center w-[40px] overflow-hidden h-[40px] rounded-full bg-backgroundAuxiliaryColor">
              <img
                src={require('@/assets/img/tw.png')}
                className="w-full h-full"
                alt=""
              />
            </div>
            <span className="text-[12px]">X-twitter</span>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
