import type { SVGProps } from 'react';

export function SaveIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_39_125)">
        <path d="M9.00496 11.5411L14.0872 7.72946H11.5461V1.37671H6.46386V7.72946H3.92276L9.00496 11.5411Z"
              fill="currentColor" />
        <path d="M15.3577 10.2706V12.8117H2.65222V10.2706H0.111115V16.6234H17.8988V10.2706H15.3577Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_39_125">
          <rect width="17.7778" height="17.7778" fill="white" transform="translate(0.111115 0.111084)" />
        </clipPath>
      </defs>
    </svg>

  );
}
